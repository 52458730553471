import '../styles/globals.css';
import type { AppProps } from 'next/app';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import { DefaultSeo } from 'next-seo';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <DefaultSeo
        title="Reddy Meet"
        titleTemplate="%s"
        defaultTitle="Reddy Meet"
        description="Modernize workflows with Reddy' s trusted collaboration tools"
        openGraph={{
          site_name: 'Reddy Meet',
          type: 'website',
          locale: "en",
          description: "Modernize workflows with Reddy's trusted collaboration tools",
          images: [
            {
              url: 'https://conf.reddy.team/image.png',
              width: 1200,
              height: 630,
              type: 'image/png',
            }
          ]
        }}
        additionalMetaTags={[
          {
            property: 'theme-color',
            content: '#070707',
          },
        ]}
      />
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
